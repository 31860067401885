define("workflows-web/components/nav-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    value: '',
    currentTabValue: Ember.computed.alias('parentView.currentTabValue'),
    classNames: ['hf-tab'],
    classNameBindings: ['isActive:hf-is-active', 'isDisabled:hf-tab-disabled'],
    actionOnClick: '',
    isActive: Ember.computed('value', 'currentTabValue', {
      get: function get() {
        return this.get('value') === this.get('currentTabValue');
      }
    }),
    click: function click() {
      this.set('currentTabValue', this.get('value'));
      if (this.get('actionOnClick')) {
        this.actionOnClick(this.get('value'));
      }
    }
  });
});