define("workflows-web/workflows/add/form-model", ["exports", "workflows-web/form-models/base-form", "workflows-web/utilities/object", "workflows-web/utilities/request"], function (_exports, _baseForm, _object, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _baseForm.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var rawFields = this.get('rawFields');
      var basicFieldKeys = this.get('basicFieldKeys');
      this.set('dataSource', this.get('dataSource'));
      this.set('basicFieldKeys', basicFieldKeys);
      this.set('fields', this._createFieldModels(rawFields));
      this.set('errors', Ember.A());
      if (!_.isEmpty(this.get('dataSource'))) {
        this._copyFromDataSource();
      }
    },
    submit: function submit() {
      var _this = this;
      var fields = this.get('fields');
      var dataSource = this.get('dataSource');
      this.set('errors', Ember.A());
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.validate().then(function () {
          _this._copyToDataSource(fields, dataSource);
          resolve();
        }).catch(function () {
          _this.scrollToError();
          reject();
        });
      });
    },
    populateErrors: function populateErrors(errors) {
      var _this2 = this;
      var fields = this.get('fields');
      if ((0, _request.isInvalid)(errors.status)) {
        (0, _object.getKeysFromObject)(errors).forEach(function (errorKey) {
          if (errorKey === 'nonFieldErrors') {
            _this2.set('errors', errors[errorKey]);
          } else {
            var field = fields.findBy('key', errorKey);
            if (Ember.isPresent(field)) {
              field.setErrors(errors[errorKey]);
            }
          }
        });
        this.scrollToError();
      }
    },
    generatePayload: function generatePayload() {
      var formModel = this.get('model');
      var basicFieldKeys = this.get('basicFieldKeys');
      var payload = Ember.Object.create();
      basicFieldKeys.forEach(function (key) {
        if (formModel[key].get('isDirty') || formModel[key].includeAlwaysInPayload) {
          var field = formModel[key];
          if (Ember.isPresent(field.transformValue)) {
            var transformedValue = field.transformValue(field);
            if (Ember.isPresent(transformedValue)) {
              payload = Ember.assign(payload, transformedValue);
            }
          } else {
            payload.set(key, field.getCleanValue());
          }
        }
      });
      return payload;
    }
  });
});